<template>
  <div class="page-question">
    <div class="banner">
      <img src="../assets/images/rediandayi.jpg" alt="" width="100%">
    </div>
    <div class="raw-context">
      <p class="title">有什么需要帮助的?</p>
      <el-row class="row-1">
        <el-col :span="4"><div class="label">关键字:</div></el-col>
        <el-col :span="16">
          <div class="search-input">
            <el-input placeholder="请输入关键词" suffix-icon="el-icon-search" v-model="queryParams.searchStr" @keyup.enter.native="search" />
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" class="search-btn" @click="search">搜索</el-button>
        </el-col>
      </el-row>
      <div class="raw-row">
        <el-button type="text" @click="handleOpen">我要提问</el-button>
      </div>
      <div class="list-wrap" v-loading="loading">
        <div class="list-item" v-for="(item, n) in dataList" :key="n" @click="toDetail(item)">
          <p class="row-1">{{item.content}}</p><!--市政府发的人才补助如何纳税?-->
          <!--<p class="desc">{{item.content}}</p>-->
          <p class="row-3"><!--<span class="address">https://www.shui5.cn/article/9e/127330.htm</span>-->
            <span class="time">{{item.createTime.substring(0, 10)}}</span>
            <span class="type">{{item.category}}</span><span class="number">访问人气：{{item.attention || 0}}</span></p>
        </div>
        <el-empty :image-size="100" v-if="!dataList.length"></el-empty>
      </div>
      <div class="row-page">
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="pageChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.open" width="600" center :close-on-click-modal="false"
      :destroy-on-close="true">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <!--<el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="简要标题"></el-input>
        </el-form-item>-->
        <el-form-item label="问题分类" prop="category">
          <el-select v-model="form.category" placeholder="请选择类型" value-key="id">
            <el-option :label="item.name" :value="item" v-for="item in categoryOptions" :key="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" prop="content">
          <el-input type="textarea" v-model="form.content" placeholder="请详细描述你的问题" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.open = false">取 消</el-button>
        <el-button type="primary" @click="onSureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from '@/utils'
export default {
  name: 'answerQuestion',
  data () {
    this.rules =
    {
      title: [
        { required: true, message: '请填写标题' }
      ],
      category: [
        { required: true, message: '请选择问题类型', trigger: 'change' }
      ],
      content: [
        { required: true, message: '请描述您的疑惑' }
      ]
    }
    return {
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        searchStr: ''
      },
      total: 0,
      dataList: [],
      dialog: {
        title: '我要提问',
        open: false
      },
      form: {},
      loading: false,
      categoryOptions: [] // 问题类型
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    search (value) {
      // console.log(11, value)
      this.getData(this.queryParams)
    },
    getList () {
      this.loading = true
      this.API.getHotQuestion(this.queryParams).then(({ code, data }) => {
        this.total = data.total
        this.dataList = data.list
      }).finally(() => {
        this.loading = false
      })
    },
    pageChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    },

    toDetail (item) {
      // this.$router.push(`/quesDetail/${item.id}`)
      window.open(`/#/quesDetail/${item.id}`)
    },
    onSureClick () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { category, ...params } = this.form
          const cateId = category.id
          const cateCode = category.code
          const cateName = category.name
          this.API.questionPush({ ...params, cateId, cateCode, cateName }).then(({ code, data }) => {
            if (code === 200) {
              this.$message.success('您的问题已提交, 可在个人中心中查看状态')
              this.dialog.open = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getCategory () {
      this.API.getCategory().then(({ code, data }) => {
        this.categoryOptions = data
      })
    },
    handleOpen () {
      if (this.userInfo) {
        this.dialog.open = true
      } else {
        this.$store.commit('SET_SHOW_LOGIN', true)
      }
    }
  },
  created () {
    this.getList()
    this.getCategory()
    this.getData = debounce(this.getList, 300)
  }
}
</script>

<style scoped lang="scss">
.page-question{
  background: #ffffff;
  text-align: left;
  .raw-context{
    width: 1200px;
    margin: 0 auto;
    .title{
      padding: 40px 0;
      font-size: 36px;
      color: #606266;
      text-align: center;
    }
    .row-1{
      line-height: 40px;
      .label{
        text-align: right;
        margin-right: 14px;
      }
      .search-btn{
        margin-left: 14px;
      }
    }
    .raw-row{
      text-align: right;
    }
    .list-wrap{
      min-height: 200px;
      .list-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px dotted rgba(120, 120, 120, 0.8);
        padding: 10px 15px;
        margin: 14px 0;
        cursor: pointer;
        text-align: left;
        p{
          line-height: 30px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.row-1{
            font-size: 16px;
          }
          &.row-3{
            font-size: 14px;
            color: #999;
            .address{
              margin-right: 60px;
            }
            .time{
              margin-right: 60px;
            }
            .number{
              float: right;
            }
          }
        }
      }
    }
  }
  .row-page{
    text-align: center;
  }
}
</style>
